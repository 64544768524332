import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';

@Injectable({
  providedIn: 'root'
})
export class SimilarService {

  private _page = 1;  // pagina iniziale
  private _resourceName = ''; // nome della risorsa
  private _searchFields = 'title'; // in caso di ricerca, dove deve cercare
  private _sorter = 'title'; // campo da sortare

  constructor(private baseProvider: BaseService) { }

    /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(coll?: string, id?: string, search?: any, searchField?: string) {
    id = id || null;
    this._resourceName = coll;
    search = search || '';
    if (id === null && this._resourceName === 'stats') {
      // richiedo la lista
      this._searchFields = this._searchFields || '_id';
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields:  this._searchFields,
        filter: JSON.stringify({ 'inProjectionNow': true })
      });
    }else if( id === null && this._resourceName === 'confidence'){
      (searchField == 'order') ? this._sorter = 'title' : this._sorter = 'order';
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: searchField,
        search: search,
        sort: this._sorter,
        filter: JSON.stringify({ 'inProjectionNow': true })
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

   /**
   * Setta una nuova pagina
   * @param page
   */
    setPage(page: number) {
      this._page = page;
    }


}
