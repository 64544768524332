import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from './providers/auth/guard.service';

import { LoginComponent } from './pages/login/login.component';
import { BaseComponent } from './pages/base/base.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UsersComponent } from './pages/users/users.component';
import { LicensesComponent } from './pages/licenses/licenses.component';
import { GuardService } from './services/auth/guard.service';
import { LicenseSingleComponent } from './pages/license-single/license-single.component';
import { UserSingleComponent } from './pages/user-single/user-single.component';
import { FilmsComponent } from './pages/films/films.component';
import { FilmSingleComponent } from './pages/film-single/film-single.component';
import { CinemasComponent } from './pages/cinemas/cinemas.component';
import { CinemaSingleComponent } from './pages/cinema-single/cinema-single.component';
import { SimilarComponent } from './pages/similar/similar.component';

// Components
const routes: Routes = [
  {
    component: LoginComponent,
    path: 'login'
  },
  {
    canActivate: [ GuardService ],
    component: BaseComponent,
    path: '',
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'user/:id',
        component: UserSingleComponent
      },
      {
        path: 'licenses',
        component: LicensesComponent
      },
      {
        path: 'license/:id',
        component: LicenseSingleComponent
      },
      {
        path: 'films',
        component: FilmsComponent
      },
      {
        path: 'film/:id',
        component: FilmSingleComponent
      },
      {
        path: 'cinemas',
        component: CinemasComponent
      },
      {
        path: 'film-associazione',
        component : SimilarComponent
      },
      {
        path: 'cinema/:id',
        component: CinemaSingleComponent
      }]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes);

