import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  nomeutente;
  admin;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result:any) => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private api: ApiService) {
    this.router.events.subscribe(event => {
    });
  }

  ngOnInit() {
    this.api.get('/profile').subscribe((user) => {
        if (user && user['success'] === false && user['tologin'] && user['tologin'] === true) {
          this.router.navigate(['/login']);
        }

        if (user && user['data'][0]) {
          this.admin = user['data'][0]['admin'];

          // se non sono amministratore e tento a mano
          // di entrare
          if (!this.admin && (
            this.router.url.includes('/users') ||
            this.router.url.includes('/user/') ||
            this.router.url.includes('/licenses') ||
            this.router.url.includes('/license/')
          )) {
            this.router.navigate(['/']);
          }

          this.nomeutente = user['data'][0]['name'];
        }
      });
  }

}
