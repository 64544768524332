import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { UsersService } from 'src/app/providers/users.service';
import { UtilsService } from 'src/app/services/utils.service';
import { LicensesService } from 'src/app/providers/licenses.service';
import { Md5 } from 'ts-md5/dist/md5';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.scss']
})
export class UserSingleComponent implements OnInit {

  form: UntypedFormGroup;
  loading = true;
  title = 'Inserisci un nuovo utente';
  id;
  licenses = [];

  // in caso la password cambi
  passwordChange = false;

  hide = true;

  toggleHide() {
    this.hide = !this.hide;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private users: UsersService,
    private utils: UtilsService,
    private licenze: LicensesService,
    @Inject(Md5) private md5: Md5
  ) {
    // inizializzo la form
    this.form = this.users.getForm();
  }

  updateFormChanges() {
    // setto il fatto che la password è cambiata
    // faccio questo perchè la password è in formato MD5
    this.form.controls['password'].valueChanges
      .subscribe(() => {
        this.passwordChange = true;
      });
  }

  ngOnInit() {
    this.licenze.get()
      .subscribe((licenses) => {
        if (licenses && licenses.success && licenses.success === true) {
          this.licenses = licenses.data;
          this.route.params.subscribe((params) => {
            if (params && params.id && params.id === 'new') {
              this.form = this.users.getForm();
              this.updateFormChanges();
              this.loading = false;
              this.id = params.id;
            } else {
              this.title = 'Modifica utente';
              this.id = params.id;
              this.users.get(params.id)
                .subscribe((data) => {
                  if (data && data.success && data.success === true) {
                    this.initializeForm(data.data);
                    this.loading = false;
                  } else {
                    this.router.navigate(['/users']);
                  }
                });
            }
          });

        } else {
          this.router.navigate(['/users']);
        }
      });
  }

  initializeForm(userData) {
    // Initialize your form with user data
    this.form = this.users.getForm(userData);

    // Convert 'scadenza' to 'YYYY-MM-DD' format
    if (userData && userData.licenza_cineservice && userData.licenza_cineservice.scadenza) {
      const formattedScadenza = formatDate(userData.licenza_cineservice.scadenza, 'yyyy-MM-dd', 'en-US');
      this.form.get('licenza_cineservice').get('scadenza').setValue(formattedScadenza);
    }

    // Call this function to update form changes related to password
    this.updateFormChanges();
  }

  generateToken() {
    this.form.get('licenza_cineservice').get('licenza_id').setValue(this.utils.generateUUID());
  }

  annulla() {
    this.router.navigate(['/users']);
  }

  submit() {
    if (this.form.valid) {

      // se trovo che la password è
      // cambiata
      if (this.passwordChange) {
        this.form.controls['password'].setValue(this.md5.appendStr(this.form.value['password']).end());
      }

      this.loading = true;
      this.users.save(this.form)
        .subscribe((result) => {
          if (result && result['success'] === true) {
            this.loading = false;
            this.router.navigate(['/users']);
          } else {
            this.loading = false;
          }
        });
    }
  }

  delete() {
    if (confirm('Si è sicuri di eliminare questa voce?')) {
      this.loading = true;
      this.users.delete(this.id)
        .subscribe((result) => {
          if (result && result.success && result.success === true) {
            this.loading = false;
            this.router.navigate(['/users']);
          } else {
            alert(`Errore durante l'eliminazione di questa risorsa.`);
          }
        });
    }
  }

}
