import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { LicensesService } from 'src/app/providers/licenses.service';

@Component({
  selector: 'app-license-single',
  templateUrl: './license-single.component.html',
  styleUrls: ['./license-single.component.scss']
})
export class LicenseSingleComponent implements OnInit {

  form: UntypedFormGroup;
  loading = true;
  title = 'Inserisci una nuova licenza';
  id;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private license: LicensesService
  ) {
    // inizializzo la form
    this.form = this.license.getForm();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params && params.id && params.id === 'new') {
        // sono un nuovo elemento
        this.form = this.license.getForm();
        this.loading = false;
        this.id = params.id;
      } else {
        // sono un elemento da modificare
        this.title = 'Modifica licenza';
        this.id = params.id;
        this.license.get(params.id)
          .subscribe((data) => {
            if (data && data.success && data.success === true) {
              this.form = this.license.getForm(data.data);
              this.loading = false;
            } else {
              this.router.navigate(['/licenses']);
            }
          });
      }
    });
  }

  annulla() {
    this.router.navigate(['/licenses']);
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      this.license.save(this.form)
        .subscribe((result) => {
          if (result && result['success'] === true) {
            this.loading = false;
            this.router.navigate(['/licenses']);
          } else {
            this.loading = false;
          }
        });
    }
  }

  delete() {
    if (confirm('Si è sicuri di eliminare questa voce?')) {
      this.loading = true;
      this.license.delete(this.id)
        .subscribe((result) => {
          if (result && result.success && result.success === true) {
            this.loading = false;
            this.router.navigate(['/licenses']);
          } else {
            alert(`Errore durante l'eliminazione di questa risorsa.`);
          }
        });
    }
  }

}
