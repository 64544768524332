import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MaterialModule } from './modules/material/material.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';

import { RouterModule } from '@angular/router';
import { routing } from './app.routes';

// gestione delle lingue locali
import { registerLocaleData, CommonModule } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { AppComponent } from './app.component';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from './pages/shared.module';
import { ApiService } from './services/api/api.service';
import { GuardService } from './services/auth/guard.service';
import { BaseService } from './providers/_base.service';
import { LicensesService } from './providers/licenses.service';
import { UsersService } from './providers/users.service';
import { UtilsService } from './services/utils.service';
import { Md5 } from 'ts-md5';
import { HttpClientModule } from '@angular/common/http';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    routing,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_q9y-6ltO1A8WnK17D7DEiEbHXbHHQSY',
      libraries: ['geometry', 'places'],
      language:'it',
      region : 'IT'
    }),
    MatChipsModule
  ],
  exports: [
    AppComponent
  ],
  // tslint:disable-next-line:max-line-length
  bootstrap: [AppComponent],
  providers: [ { provide: LOCALE_ID, useValue: 'it-IT'}, ApiService, GuardService, BaseService, LicensesService, UsersService, UtilsService, Md5 ],
})
export class AppModule { }
