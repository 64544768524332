import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() page: number;
  @Input() totalPage: number;
  @Input() loading: boolean;
  @Input() pagineDaVisualizzare: number;

  @Output() goPage = new EventEmitter<number>();

  constructor() { }

  onPage(n: number): void {
    this.goPage.emit(n);
  }

  getPages(): number[] {
    const p = this.page || 1;
    const pagesToShow = 5;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < this.totalPage) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }

}
