import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  public formSubmitAttempt: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private api: ApiService) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      // auth
      this.api.login(this.form.value.email, this.form.value.password).subscribe((result) => {
        console.log(result)
          if (result && result.success) {
            localStorage.setItem('session_id', result['session_id']);
            this.router.navigate(['/']);
            this.formSubmitAttempt = false;
          } else {
            if (result.success === false) {
              this.form.controls['password'].setValue('');
              alert(result['message']);
              this.formSubmitAttempt = false;
            }
          }
        }, () => {
          this.formSubmitAttempt = false;
        });
    }
  }

  ngOnInit() {
    localStorage.removeItem('session_id');
  }

}
