import { LayoutModule } from '@angular/cdk/layout';
import { UsersService } from 'src/app/providers/users.service';
import { LicensesService } from 'src/app/providers/licenses.service';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from '../app.component';
import { LoginComponent } from './login/login.component';
import { BaseComponent } from './base/base.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { LicensesComponent } from './licenses/licenses.component';
import { LicenseSingleComponent } from './license-single/license-single.component';
import { UserSingleComponent } from './user-single/user-single.component';
import { DialogSimilarDialog, FilmsComponent } from './films/films.component';
import { FilmSingleComponent } from './film-single/film-single.component';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { CinemasComponent, DialogCinemas } from './cinemas/cinemas.component';
import { CinemaSingleComponent } from './cinema-single/cinema-single.component';
import { DialogFilmDialog, SimilarComponent } from './similar/similar.component';
import { MaterialModule } from '../modules/material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../services/api/api.service';
import { GuardService } from '../services/auth/guard.service';
import { BaseService } from '../providers/_base.service';
import { UtilsService } from '../services/utils.service';
import { Md5 } from 'ts-md5/dist/md5';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AddSimilarComponent } from './add-similar/add-similar.component';
import { HttpClientModule } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DocumentationComponent } from './documentation/documentation.component';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
    declarations: [
        AddSimilarComponent,
        LoginComponent,
        BaseComponent,
        DashboardComponent,
        UsersComponent,
        LicensesComponent,
        LicenseSingleComponent,
        UserSingleComponent,
        FilmsComponent,
        FilmSingleComponent,
        PaginationComponent,
        CinemasComponent,
        CinemaSingleComponent,
        SimilarComponent,
        DialogFilmDialog,
        DialogSimilarDialog,
        DialogCinemas,
        DocumentationComponent
    ],
    imports: [
        FormsModule,
        MatSortModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MaterialModule,
        MatRadioModule,
        LayoutModule,
        EditorModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        MatChipsModule
    ],
    exports: [
        LoginComponent,
        BaseComponent,
        DashboardComponent,
        UsersComponent,
        LicensesComponent,
        LicenseSingleComponent,
        UserSingleComponent,
        FilmsComponent,
        FilmSingleComponent,
        PaginationComponent,
        CinemasComponent,
        CinemaSingleComponent,
        SimilarComponent,
        DialogFilmDialog,
        DialogSimilarDialog,
        DialogCinemas,
        AddSimilarComponent,
        DocumentationComponent
    ],
    providers: [Md5],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
