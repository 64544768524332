import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LicensesService {
  private _page = 1;  // pagina iniziale
  private _resourceName = 'license'; // nome della risorsa
  private _searchFields = 'code,name'; // in caso di ricerca, dove deve cercare

  constructor(
    private baseProvider: BaseService,
    private fb: UntypedFormBuilder
  ) {
  }

  getForm(data?): UntypedFormGroup {
    data = data || {
      _id: '',
      code: '',
      name: '',
      description: '',
      collections: ''
    };

    return this.fb.group({
      _id: [data['_id'], Validators.nullValidator],
      code: [data['code'], Validators.required],
      name: [data['name'], Validators.required],
      description: [data['description'], Validators.nullValidator],
      collections: [data['collections'], Validators.required],
    });
  }

  /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(id?: string, search?: string) {
    id = id || null;
    search = search || '';

    if (id === null) {
      // richiedo la lista
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: this._searchFields,
        search: search
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

  /**
   * Esegue il salvataggio o la modifica di una risorsa
   * @param form - la form
   */
  save(form: UntypedFormGroup) {

    // sistemo le licenze
    if (form.value.collections.length > 1 && form.value.collections.trim() !== '*') {
      const newCollections = [];
      form.value['collections'].split(',').map(item => {
        newCollections.push(item.toLowerCase().trim());
      });
      form.controls['collections'].setValue(newCollections);
    }


    if (form.value['_id'] !== null && form.value['_id'] !== '' && form.value['_id'].length > 0) {
      // sono in modifica
      return this.baseProvider.update(this._resourceName, form.value);
    } else {
      // sono in inserimento
      const d = form.value;
      delete d._id;
      return this.baseProvider.insert(this._resourceName, d);
    }
  }

  /**
   * Esegue l'eliminazione
   * @param id
   */
  delete(id: string) {
    return this.baseProvider.delete(this._resourceName, id);
  }


}
