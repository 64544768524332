import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private _page = 1;  // pagina iniziale
  private _resourceName = 'users'; // nome della risorsa
  private _searchFields = 'name,email'; // in caso di ricerca, dove deve cercare

  constructor(
    private baseProvider: BaseService,
    private fb: UntypedFormBuilder
  ) {
  }

  getForm(data?: any): FormGroup {
    // Define a default structure for your form data
    const defaultData = {
      _id: '',
      name: '',
      email: '',
      password: '',
      admin: false,
      portal: false,
      licenza: '',
      expired: '',
      token_id: '',
      // Note: These fields seem to be redundant with what is inside licenza_cineservice
      licenza_id: '',
      cinema_id: '',
      scadenza: '',
      licenza_cineservice: {
        cinema_id: '',
        licenza_id: '',
        scadenza: ''
      }
    };

    // Merge the defaultData with the actual data provided
    data = { ...defaultData, ...data };
    data.licenza_cineservice = { ...defaultData.licenza_cineservice, ...data.licenza_cineservice };

    return this.fb.group({
      _id: [data._id, Validators.nullValidator],
      name: [data.name, Validators.required],
      email: [data.email, Validators.required],
      password: [data.password, Validators.nullValidator],
      admin: [data.admin, Validators.required],
      portal: [data.portal, Validators.required],
      licenza: [data.licenza, Validators.nullValidator],
      expired: [data.expired, Validators.nullValidator],
      token_id: [data.token_id, Validators.nullValidator],
      // Map the root level fields to the form controls
      licenza_id: [data.licenza_id, Validators.nullValidator],
      cinema_id: [data.cinema_id, Validators.nullValidator],
      scadenza: [data.scadenza, Validators.nullValidator],
      // Nested FormGroup for licenza_cineservice
      licenza_cineservice: this.fb.group({
        cinema_id: [data.licenza_cineservice.cinema_id, Validators.nullValidator],
        licenza_id: [data.licenza_cineservice.licenza_id, Validators.nullValidator],
        scadenza: [data.licenza_cineservice.scadenza, Validators.nullValidator]
      })
    });
  }

  /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(id?: string, search?: string) {
    id = id || null;
    search = search || '';

    if (id === null) {
      // richiedo la lista
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: this._searchFields,
        search: search
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

  /**
   * Esegue il salvataggio o la modifica di una risorsa
   * @param form - la form
   */
  save(form: UntypedFormGroup) {
    if (form.value['_id'] !== null && form.value['_id'] !== '' && form.value['_id'].length > 0) {
      // sono in modifica
      return this.baseProvider.update(this._resourceName, form.value);
    } else {
      // sono in inserimento
      const d = form.value;
      delete d._id;
      return this.baseProvider.insert(this._resourceName, d);
    }
  }

  /**
   * Esegue l'eliminazione
   * @param id
   */
  delete(id: string) {
    return this.baseProvider.delete(this._resourceName, id);
  }


}
