
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Http } from '@angular/http';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],

})

export class DocumentationComponent implements OnInit {
  @ViewChild('iframe') iframe ;

  data: any ;
  open = false;
  textDoc = 'Apri documentazione';
  btnColor = 'primary';
  dataCss: any;
  currUrl: any;
  doc:any;

  constructor(private dom: DomSanitizer, private http: Http) { }

  ngOnInit() {
    this.getFileCss().subscribe(r => {
      this.dataCss = r._body;
    });
  }

  public getFileCss(): Observable<any> {
    return this.http.get('./assets/styleDoc.css');
  }

  onChnagediFrame() {
    if(open){
      this.doc = this.iframe.nativeElement.contentWindow.document;
      this.currUrl = this.doc.location.href;
      let style = this.doc.createElement('style');
      let head = this.doc.head;
      let body = this.doc.querySelectorAll('body')[0];
      let title = this.doc.querySelectorAll('.page-title')[0].innerHTML;
      this.doc.querySelectorAll('nav>ul:last-child')[0].setAttribute('id', 'globalDoc');
      if (title !== undefined && title !== 'Home' && title !== 'Global') {
        title = title.split(':')[1].replace(' ', '');
        for (let a of this.doc.querySelectorAll('li')) {
          if (a.textContent === title) {
            a.setAttribute('id', 'actived');
            setTimeout(() => {
              this.doc.getElementById('actived').scrollIntoView();
            }, 10);
          }
        }
      }
      this.doc.getElementById('globalDoc').addEventListener("click",function(e) {
        console.log(e.target.baseURI)
        this.currUrl = e.target.baseURI;
      });
      head.appendChild(style);
      style.append(this.dataCss);
      body.setAttribute('class', 'iframeBodyCss');
    }
  }

  openDoc(open){
    this.open = !this.open;
    this.open ?
      (this.textDoc = 'Chiudi documentazione', this.btnColor = 'accent') :
      (this.textDoc = 'Apri documentazione', this.btnColor = 'primary', this.currUrl = '');
    this.data = this.dom.bypassSecurityTrustResourceUrl('/out/');
  }

}
