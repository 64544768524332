/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { CinemasService } from 'src/app/providers/cinemas.service';

import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-cinema-single',
  templateUrl: './cinema-single.component.html',
  styleUrls: ['./cinema-single.component.scss']
})

export class CinemaSingleComponent implements OnInit {

  @ViewChild('autoSearch') autoSearch: ElementRef<HTMLInputElement>;
  @ViewChild('autoRegione') matAutoRegioneComplete: MatAutocomplete;
  @ViewChild('regioneInput') regioneInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /**Controllo dell'input per la ricerca del posto */
  searchControl: UntypedFormControl;

  form: UntypedFormGroup;
  loading = true;

  title = '';
  id;

  lat:number = 0;
  lng:number = 0;
  titleCinema = '';
  icon = {
    url: "../../../assets/images/movie.png",
    scaledSize: {
      height : 30,
      width: 30
    }
  };

  indirizzo = '';
  comune = '';
  cap = '';
  provincia = '';

  regioni = [];
  filteredRegioni: Observable<string[]>;
  regioneCtrl = new UntypedFormControl();



  private _filter(all, value: string): string[] {
    const filterValue = value.toLowerCase();
    return all.filter(d => d.toLowerCase().indexOf(filterValue) >= 0);
  }

  constructor(private route: ActivatedRoute,private router: Router,
              private cinemas: CinemasService,private api: ApiService,
              private mapsAPILoader: MapsAPILoader, private ngZone: NgZone ) {
    // inizializzo le form
    this.form = this.cinemas.getForm();
    this.searchControl = new UntypedFormControl();
  }

  ngOnInit() {
    // prendo i generi di tutti i film
    this.cinemas.getRegione().subscribe((resultRegioni) => {
      this.regioni = resultRegioni.data;
      this.filteredRegioni = this.regioneCtrl.valueChanges.pipe(
        startWith(null),
        map((regione: string | null) => (regione && regione.length > 0) ?
          this._filter(resultRegioni.data, regione) : [])
      );
      this.route.params.subscribe((params) => {
        if (params && params.id && params.id === 'new') {
          // sono un nuovo elemento
          this.form = this.cinemas.getForm();
          this.loading = false;
          this.id = params.id;
          this.title = 'Inserisci un nuovo cinema';
        } else {
          // sono un elemento da modificare
          this.title = 'Modifica cinema';
          this.id = params.id;
          this.cinemas.get(params.id).subscribe((data) => {
            if (data && data.success && data.success === true) {
              this.form = this.cinemas.getForm(data.data);
              this.lat = parseFloat(this.form.value['lat']);
              this.lng = parseFloat(this.form.value['lng']);
              this.titleCinema = this.form.value['title'];
              this.loading = false;
            } else {
              this.router.navigate(['/cinemas']);
            }
          });
        }
      });
    });
  }

  placeChanged(event) {
    /**Carico l'api di google per poter usare l'autocompletameto delle api di google maps */
    this.mapsAPILoader.load().then(() => {
      const input = this.autoSearch.nativeElement;
      const autocomplete = new google.maps.places.Autocomplete(input,{} );
      autocomplete.setComponentRestrictions({'country': ['IT']});

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //risultati
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verifica
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //setto latitude, longitude per poter salvare nel form per il db
          this.form.controls['lat'].setValue(this.lat = place.geometry.location.lat());
          this.form.controls['lng'].setValue(this.lng = place.geometry.location.lng());
          this.getGeoLocation(this.lat,this.lng);
        });
      });
    });
  }

  placeMarker(event){
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.form.controls['lat'].setValue(this.lat);
    this.form.controls['lng'].setValue(this.lng);
    this.getGeoLocation(this.lat,this.lng);
  }

  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(lat, lng);
      const request = { location: latlng };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          const result = results[0];
          const rsltAdrComponent = result.address_components;
          const resultLength = rsltAdrComponent.length;
          if (result != null && resultLength > 0) {
            const state =  rsltAdrComponent.filter(function(addr){
              return (addr.types[0]=='country') ? 1 : 0;
            });
            if( state[0].short_name == 'IT'){
              const address =  rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='route')?1:0;
              });
              const nr_address = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='street_number')?1:0;
              });
              const add = (address.length > 0) ? address[0].long_name : "Nessun indirizzo segnalato";
              const nr_add = (nr_address.length > 0) ? nr_address[0].long_name : "Nessun numero segnalato";
              this.indirizzo = add + ', ' + nr_add;
              this.form.controls['indirizzo'].setValue(this.indirizzo);

              const cap =  rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='postal_code') ? 1 : 0;
              });
              (cap.length > 0) ? this.cap = cap[0].long_name : this.cap = "Nessun cap segnalato";
              this.form.controls['cap'].setValue(this.cap);

              const comune = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_3')?1:0;
              });
              (comune.length > 0) ? this.comune = comune[0].long_name : this.comune = "Nessun comune segnalato";
              this.form.controls['comune'].setValue(this.comune);

              const provincia = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_2')?1:0;
              });
              (provincia.length > 0) ? this.provincia = provincia[0].short_name : this.provincia = "Nessuna provincia segnalata";
              this.form.controls['provincia'].setValue(this.provincia);

              const regione = rsltAdrComponent.filter(function(addr){
                return (addr.types[0]=='administrative_area_level_1')?1:0;
              });
              (regione.length > 0) ?  this.form.controls['regione'].setValue(regione[0].long_name) : this.form.controls['regione'].setValue("Nessuna regione segnalata");

              console.log(this.indirizzo,this.cap,this.comune,this.provincia);
            } else {
              console.log("Non sei in Italia");
              alert("Non sei in Italia");
            }
          } else {
            console.log("No address available!");
          }
        }
      });
    }
  };

  annulla() {
    this.router.navigate(['/cinemas']);
  }

  submit() {
    if (this.form.valid) {
      this.loading = true;
      this.cinemas.save(this.form)
        .subscribe((result) => {
          if (result && result['success'] === true) {
            this.loading = false;
            this.router.navigate(['/cinemas']);
          } else {
            this.loading = false;
          }
        });
    }
  }

  delete() {
    if (confirm('Si è sicuri di eliminare questa voce?')) {
      this.loading = true;
      this.cinemas.delete(this.id)
        .subscribe((result) => {
          if (result && result.success && result.success === true) {
            this.loading = false;
            this.router.navigate(['/cinemas']);
          } else {
            alert(`Errore durante l'eliminazione di questa risorsa.`);
          }
        });
    }
  }

  removeChip(field: string, item: string): void {
    const tmpData = this.form.value[field] || [];
    const index = tmpData.indexOf(item);

    if (index >= 0) {
      tmpData.splice(index, 1);
      this.form.controls[field].setValue(tmpData);
    }
  }

  selectedRegioneChip(event: MatAutocompleteSelectedEvent): void {

    const tmpData =[this.form.value['regione']] || [];
    tmpData[0] = event.option.viewValue;
    this.form.controls['regione'].setValue(tmpData);
    this.regioneInput.nativeElement.value = '';
    this.regioneCtrl.setValue(null);
  }

  addInputRegioneChip(event: MatChipInputEvent): void {
    if (!this.matAutoRegioneComplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        const tmp = [this.form.value['regione']] || [];
        tmp.push(value);
        this.form.controls['regione'].setValue(tmp);
      }

      if (input) {
        input.value = '';
      }

      this.regioneCtrl.setValue(null);
    }
  }

  addImageGalleryData() {
    this.cinemas.addImageGalleryData(this.form);
  }

  removeImageGalleryData(index) {
    if (confirm(`Si è sicuri di eliminare questa scheda?`)) {
      const imageGalleryData = this.form.get('imageGalleryData') as UntypedFormArray;
      imageGalleryData.removeAt(index);
    }
  }


}
