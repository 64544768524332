import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, FormControl, FormGroup } from '@angular/forms';
import { FilmsService } from 'src/app/providers/films.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EMPTY, Observable, Subscription, forkJoin, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api/api.service';
import { MatTabGroup } from '@angular/material/tabs';
import { NgZone } from '@angular/core';


@Component({
  selector: 'app-film-single',
  templateUrl: './film-single.component.html',
  styleUrls: ['./film-single.component.scss']
})
export class FilmSingleComponent implements OnInit {

  @ViewChild('tab', { static: false }) tab: MatTabGroup;

  @ViewChild('autoGenere', { static: false }) matAutoGenereComplete: MatAutocomplete;
  @ViewChild('genereInput', { static: false }) genereInput: ElementRef<HTMLInputElement>;
  genereCtrl = new FormControl('');

  @ViewChild('autoAttore', { static: false }) matAutoAttoreComplete: MatAutocomplete;
  @ViewChild('attoreInput', { static: false }) attoreInput: ElementRef<HTMLInputElement>;
  attoreCtrl = new FormControl('');

  @ViewChild('autoRegia', { static: false }) matAutoRegiaComplete: MatAutocomplete;
  @ViewChild('regiaInput', { static: false }) regiaInput: ElementRef<HTMLInputElement>;
  regiaCtrl = new FormControl('');

  @ViewChild('autoMusiche', { static: false }) matAutoMusicheComplete: MatAutocomplete;
  @ViewChild('musicheInput', { static: false }) musicheInput: ElementRef<HTMLInputElement>;
  musicheCtrl = new FormControl('');

  @ViewChild('autoProduzione', { static: false }) matAutoProduzioneComplete: MatAutocomplete;
  @ViewChild('produzioneInput', { static: false }) produzioneInput: ElementRef<HTMLInputElement>;
  produzioneCtrl = new FormControl('');

  @ViewChild('autoFotografia', { static: false }) matAutoFotografiaComplete: MatAutocomplete;
  @ViewChild('fotografiaInput', { static: false }) fotografiaInput: ElementRef<HTMLInputElement>;
  fotografiaCtrl = new FormControl('');

  @ViewChild('autoSceneggiatura', { static: false }) matAutoSceneggiaturaComplete: MatAutocomplete;
  @ViewChild('sceneggiaturaInput', { static: false }) sceneggiaturaInput: ElementRef<HTMLInputElement>;
  sceneggiaturaCtrl = new FormControl('');

  @ViewChild('autoMontaggio', { static: false }) matAutoMontaggioComplete: MatAutocomplete;
  @ViewChild('montaggioInput', { static: false }) montaggioInput: ElementRef<HTMLInputElement>;
  montaggioCtrl = new FormControl('');

  @ViewChild('autoDistribuzione', { static: false }) matAutoDistribuzioneComplete: MatAutocomplete;
  @ViewChild('distribuzioneInput', { static: false }) distribuzioneInput: ElementRef<HTMLInputElement>;
  distribuzioneCtrl = new FormControl('');

  separatorKeysCodes: number[] = [ENTER, COMMA];

  public formFilm: FormGroup;
  public title: string = '';
  public id: string = '';
  public loading: boolean = false;
  loadingImageCopertina;
  loadingImageLocandina;
  imageUrl = environment.images;

  tramaCortaLength = 0;
  tramaCortaMaxLength = 400;
  tramaLungaLength = 0;
  tramaLungaMaxLength = 1000;

  initTramaWebEditor = {
    plugins: 'link code',
    menubar: 'edit insert format',
    valid_styles: 'text-align,color,font-size',
    toolbar: 'undo redo | styleselect | bold italic | alignleft, aligncenter, alignright | link image | code'
  };

  generi = [];
  filteredGeneri: Observable<string[]>;

  attori = [];
  filteredAttori: Observable<string[]>;

  regia = [];
  filteredRegia: Observable<string[]>;

  musiche = [];
  filteredMusiche: Observable<string[]>;

  produzione = [];
  filteredProduzione: Observable<string[]>;

  fotografia = [];
  filteredFotografia: Observable<string[]>;

  sceneggiatura = [];
  filteredSceneggiatura: Observable<string[]>;

  montaggio = [];
  filteredMontaggio: Observable<string[]>;

  distribuzione = [];
  filteredDistribuzione: Observable<string[]>;

  public areData: boolean = false;

  public proiezioni: any[] = [];
  public cinemas: any[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private films: FilmsService,
    private api: ApiService
  ) {
    // inizializzo la form
    this.formFilm = this.films.getForm();
  }

  public subscriptions = new Subscription();

  ngOnInit() {
    this.loading = true;
    this.fetchInitialData();
  }

  private fetchInitialData() {
    const allData$ = forkJoin({
      generi: this.handleError(this.films.getGeneri(), []),
      attori: this.handleError(this.films.getAttori(), []),
      regia: this.handleError(this.films.getRegia(), ''),
      musiche: this.handleError(this.films.getMusiche(), []),
      produzione: this.handleError(this.films.getProduzione(), []),
      fotografia: this.handleError(this.films.getFotografia(), ''),
      sceneggiatura: this.handleError(this.films.getSceneggiatura(), ''),
      montaggio: this.handleError(this.films.getMontaggio(), ''),
      distribuzione: this.handleError(this.films.getDistribuzione(), [])
    }).pipe(
      tap(results => { console.log('All forkJoin results:', results) }),
      catchError(error => {
        console.error('CatchError in forkJoin:', error);
        console.log('Error object:', error); // This might give you more insight.
        this.showError(error.message || JSON.stringify(error)); // Show a default message if error is empty
        return EMPTY;
      })
    );

    const allDataSubscription = allData$.subscribe({
      next: (results) => {
        this.assignDataToProperties(results);
        this.setupFilteredControls();
        this.subscribeToRouteParams();
      },
      error: (error) => this.handleLoadingError(error)
    });

    this.subscriptions.add(allDataSubscription);
  }

  private handleRouteParams(params: Params): Observable<any> {
    console.log('handleRouteParams', params)
    const isNew = params && params.id === 'new';
    console.log('isNew determination:', isNew);
    this.id = params.id;
    this.title = isNew ? 'Inserisci un nuovo film' : 'Modifica il film';
    const filmData$ = isNew ? of({ data: null }) : this.films.get(params.id);
    return filmData$.pipe(
      tap(data => {
        if (!isNew && (!data || !data.success)) {
          console.error('Invalid data:', data);
          this.router.navigate(['/films']);
        } else {
          this.formFilm = isNew ? this.films.getForm() : this.films.getForm(data.data);
          this.title += ' ' + data.data.title
        }
      }),
      catchError(error => {
        this.showError(error);
        return EMPTY;
      })
    );
  }

  private handleLoadingError(error: any) {
    this.areData = false;
    this.showError(error.message || JSON.stringify(error));

  }

  private completeLoading() {

  }

  vaiAlCinema(cinemaId: string) {
    this.router.navigate(['/cinema', cinemaId]);
  }

  vaiAlleProiezioni(url: string) {
    window.open(url, '_blank');
  }

  private subscribeToRouteParams() {
    const routeParamsSubscription = this.route.params.pipe(
      tap(params => { console.log('Received params:', params) }),
      switchMap(params => this.handleRouteParams(params))
    ).subscribe({
      next: (dataFilm) => {
        this.changeDetectorRef.detectChanges();
        if (dataFilm.data.cinema){
          this.cinemas = dataFilm.data.cinema[0];
        }
        if (dataFilm.data.proiezioni) {
          this.proiezioni = dataFilm.data.proiezioni['CREA'];
          if (this.proiezioni && this.proiezioni.length > 0) {
            const actorsString = this.proiezioni[0]['Actors'];
            const categoryString = this.proiezioni[0]['Category'];
            const originalTitle = this.proiezioni[0]['OriginalTitle'];

            let edited = false;

            if (actorsString) {
              const actorsArray = actorsString.split(', ');
              const lowercaseActorsArray = actorsArray.map(actor => actor.toLowerCase());
              const currentActors = this.formFilm.get('attoriData').value.map(actor => actor.toLowerCase());
              const missingLowercaseActors = lowercaseActorsArray.filter(actor => !currentActors.includes(actor));

              if (missingLowercaseActors.length > 0) {
                const updatedActors = [...currentActors, ...missingLowercaseActors.map(actor => actor.charAt(0).toUpperCase() + actor.slice(1))];
                this.formFilm.get('attoriData').setValue(updatedActors.map(actor => actor.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), { emitEvent: true });
                edited = true;
              }
            }

            if (categoryString) {
              const currentGenres = this.formFilm.get('genereFilmData').value.map(genre => genre.toLowerCase());
              const categoryGenresArray = categoryString.split(', ');
              const formattedCategoryGenresArray = categoryGenresArray.map(genre => genre.trim().toUpperCase());
              const missingGenres = formattedCategoryGenresArray.filter(genre => !currentGenres.includes(genre.toLowerCase()));

              if (missingGenres.length > 0) {
                const updatedGenres = [...currentGenres, ...missingGenres];
                this.formFilm.get('genereFilmData').setValue(updatedGenres.map(genre => genre.charAt(0).toUpperCase() + genre.slice(1).toLowerCase()), { emitEvent: true });
                edited = true;
              }
            }

            if (originalTitle && !this.formFilm.get('titoloOriginale').value) {
              this.formFilm.get('titoloOriginale').setValue(originalTitle, { emitEvent: true });
              edited = true;
            }

            if (edited) {
              this.submit(false);
            }
          }
        }


        this.loading = false;
        this.updateWatchers();
      },
      error: (error) => this.handleLoadingError(error),
      complete: () => this.completeLoading()
    });

    this.subscriptions.add(routeParamsSubscription);
  }

  unisciStringheConSpazio(array: string[]): string {
    return array.join(' ');
  }

  // Generic error handler to provide default values and log errors
  private handleError<T>(observable$: Observable<T>, defaultValue: T): Observable<T> {
    return observable$.pipe(
      catchError(error => {
        console.error('Error in observable:', error);
        return of(defaultValue); // Provide a default value in case of an error
      })
    );
  }

  assignDataToProperties(results: any) {
    this.generi = results.generi.data;
    this.attori = results.attori.data;
    this.regia = results.regia.data;
    this.musiche = results.musiche.data;
    this.produzione = results.produzione.data;
    this.fotografia = results.fotografia.data;
    this.sceneggiatura = results.sceneggiatura.data;
    this.montaggio = results.montaggio.data;
    this.distribuzione = results.distribuzione.data;
  }

  setupFilteredControls() {
    this.filteredGeneri = this.setupFilteredControl(this.genereCtrl, this.generi);
    this.filteredAttori = this.setupFilteredControl(this.attoreCtrl, this.attori);
    this.filteredRegia = this.setupFilteredControl(this.regiaCtrl, this.regia);
    this.filteredMusiche = this.setupFilteredControl(this.musicheCtrl, this.musiche);
    this.filteredProduzione = this.setupFilteredControl(this.produzioneCtrl, this.produzione);
    this.filteredFotografia = this.setupFilteredControl(this.fotografiaCtrl, this.fotografia);
    this.filteredSceneggiatura = this.setupFilteredControl(this.sceneggiaturaCtrl, this.sceneggiatura);
    this.filteredMontaggio = this.setupFilteredControl(this.montaggioCtrl, this.montaggio);
    this.filteredDistribuzione = this.setupFilteredControl(this.distribuzioneCtrl, this.distribuzione);
  }

  setupFilteredControl(control: FormControl, data: any[]): Observable<any[]> {
    return control.valueChanges.pipe(
      startWith(null),
      map(value => value ? this._filter(data, value) : data?.slice()),
      catchError(err => {
        console.error('Errore nel filtraggio dei dati:', err);
        return of([]); // Ritorna un Observable di un array vuoto in caso di errore
      })
    );
  }

  private _filter(all: any[], value: string): string[] {

    if (!Array.isArray(all)) {
      console.warn('Il dato fornito non è un array:', all);
      return []; // Ritorna un array vuoto se il dato non è valido
    }

    const filterValue = value.toLowerCase();
    return all.filter(d => d.toLowerCase().indexOf(filterValue) >= 0);
  }

  updateWatchers() {
    this.formFilm.get('tramaCorta').valueChanges
      .subscribe((change) => {
        this.tramaCortaLength = change.length;
      });
    this.formFilm.get('tramaLunga').valueChanges
      .subscribe((change) => {
        this.tramaLungaLength = change.length;
      });
  }


  showError(error: any) {
    console.log(error)
  }

  // Make sure to clean up subscriptions to avoid memory leaks
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSequenceChangeEvent(evt) {
    console.log(evt)
  }

  annulla() {
    this.router.navigate(['/films']);
  }

  submit(redirect = true) {
    if (this.formFilm.invalid) {
      // Il modulo nel complesso non è valido
      console.log('Il modulo non è valido');

      // Verifica quali campi specifici non sono validi
      Object.keys(this.formFilm.controls).forEach(fieldName => {
        const control = this.formFilm.get(fieldName);
        if (control && control.invalid) {
          console.log(`Il campo ${fieldName} non è valido`);
          // Puoi anche gestire i campi specifici che non sono validi qui
        }
      });
    } else {
      this.films.save(this.formFilm)
        .subscribe((result) => {
          console.log('result', result)
          if (result && result['success'] === true) {
            if (redirect){
              this.router.navigate(['/films']);
            }else{
              window.location.reload();
            }
          } else {

          }
        });
    }
  }


  onFileCopertinaChange(evt) {
    this.loadingImageCopertina = true;

    const target: DataTransfer = (evt.target);
    if (target.files.length === 1) {
      this.api.uploadImage('films', target.files[0])
        .subscribe(fileName => {
          if (fileName !== null) {
            // ho caricato il file correttamente
            this.formFilm.controls['thumbnail_src_name_copertina'].setValue(fileName);
          }
        });
      this.loadingImageCopertina = false;
    } else {
      this.loadingImageCopertina = false;
    }
  }

  isString(val) {
    return typeof val == 'string' ? true : false
  }

  onFileLocandinaChange(evt) {
    this.loadingImageLocandina = true;

    const target: DataTransfer = (evt.target);
    if (target.files.length === 1) {
      this.api.uploadImage('films', target.files[0])
        .subscribe(fileName => {
          if (fileName !== null) {
            // ho caricato il file correttamente
            this.formFilm.controls['thumbnail_src_name'].setValue(fileName);
          }
        });
      this.loadingImageLocandina = false;
    } else {
      this.loadingImageLocandina = false;
    }
  }

  delete() {
    if (confirm('Si è sicuri di eliminare questa voce?')) {

      this.films.delete(this.id)
        .subscribe((result) => {
          if (result && result.success && result.success === true) {

            this.router.navigate(['/films']);
          } else {
            alert(`Errore durante l'eliminazione di questa risorsa.`);
          }
        });
    }
  }

  removeChip(field: string, item: string): void {
    const tmpData = this.formFilm.value[field] || [];
    const index = tmpData.indexOf(item);

    if (index >= 0) {
      tmpData.splice(index, 1);
      this.formFilm.controls[field].setValue(tmpData);
    }
  }

  selectedGenereChip(event: MatAutocompleteSelectedEvent): void {
    // Aggiungi il genere selezionato all'array del controllo del form
    const tmpData = this.formFilm.value['genereFilmData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['genereFilmData'].setValue(tmpData);

    // Pulisci il valore di input e reimposta il controllo del form
    this.genereInput.nativeElement.value = '';
    this.genereCtrl.setValue(null);
  }

  addInputGenereChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Aggiungi il genere se il valore è presente
    if (value) {
      const tmp = this.formFilm.value['genereFilmData'] || [];
      tmp.push(value);
      this.formFilm.controls['genereFilmData'].setValue(tmp);
    }

    // Pulisci il valore di input usando chipInput.inputElement
    if (event.chipInput && event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }

    // Reimposta il controllo del form associato all'autocompletamento
    this.genereCtrl.setValue(null);
  }

  selectedAttoreChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['attoriData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['attoriData'].setValue(tmpData);

    // Assicurati che il riferimento a 'attoreInput' sia corretto
    this.attoreInput.nativeElement.value = '';
    this.attoreCtrl.setValue(null);
  }

  addInputAttoreChip(event: MatChipInputEvent): void {
    // Rimuovi il controllo isOpen se non è più necessario
    const value = (event.value || '').trim();

    if (value) {
      const tmp = this.formFilm.value['attoriData'] || [];
      tmp.push(value);
      this.formFilm.controls['attoriData'].setValue(tmp);
    }

    // Utilizza 'event.chipInput.inputElement' per la coerenza e per evitare deprecazioni
    if (event.chipInput && event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }

    this.attoreCtrl.setValue(null);
  }

  selectedRegiaChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['regiaData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['regiaData'].setValue(tmpData);
    this.regiaInput.nativeElement.value = '';
    this.regiaCtrl.setValue(null);
  }

  addInputRegiaChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['regiaData'] || [];
      tmp.push(value);
      this.formFilm.controls['regiaData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.regiaCtrl.setValue(null);
  }

  selectedMusicaChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['musicheData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['musicheData'].setValue(tmpData);
    this.musicheInput.nativeElement.value = '';
    this.musicheCtrl.setValue(null);
  }

  addInputMusicaChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['musicheData'] || [];
      tmp.push(value);
      this.formFilm.controls['musicheData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.musicheCtrl.setValue(null);
  }

  selectedProduzioneChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['produzioneData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['produzioneData'].setValue(tmpData);
    this.produzioneInput.nativeElement.value = '';
    this.produzioneCtrl.setValue(null);
  }

  addInputProduzioneChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['produzioneData'] || [];
      tmp.push(value);
      this.formFilm.controls['produzioneData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.produzioneCtrl.setValue(null);
  }

  selectedFotografiaChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['fotografiaData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['fotografiaData'].setValue(tmpData);
    this.fotografiaInput.nativeElement.value = '';
    this.fotografiaCtrl.setValue(null);
  }

  addInputFotografiaChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['fotografiaData'] || [];
      tmp.push(value);
      this.formFilm.controls['fotografiaData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.fotografiaCtrl.setValue(null);
  }

  selectedSceneggiaturaChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['sceneggiaturaData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['sceneggiaturaData'].setValue(tmpData);
    this.sceneggiaturaInput.nativeElement.value = '';
    this.sceneggiaturaCtrl.setValue(null);
  }

  addInputSceneggiaturaChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['sceneggiaturaData'] || [];
      tmp.push(value);
      this.formFilm.controls['sceneggiaturaData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.sceneggiaturaCtrl.setValue(null);
  }

  selectedMontaggioChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['montaggioData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['montaggioData'].setValue(tmpData);
    this.montaggioInput.nativeElement.value = '';
    this.montaggioCtrl.setValue(null);
  }

  addInputMontaggioChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['montaggioData'] || [];
      tmp.push(value);
      this.formFilm.controls['montaggioData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.montaggioCtrl.setValue(null);
  }

  selectedDistribuzioneChip(event: MatAutocompleteSelectedEvent): void {
    const tmpData = this.formFilm.value['distribuzioneData'] || [];
    tmpData.push(event.option.viewValue);
    this.formFilm.controls['distribuzioneData'].setValue(tmpData);
    this.distribuzioneInput.nativeElement.value = '';
    this.distribuzioneCtrl.setValue(null);
  }

  addInputDistribuzioneChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const tmp = this.formFilm.value['distribuzioneData'] || [];
      tmp.push(value);
      this.formFilm.controls['distribuzioneData'].setValue(tmp);
    }
    if (event.chipInput) {
      event.chipInput.clear();
    }
    this.distribuzioneCtrl.setValue(null);
  }

  addVideoGalleryData() {
    this.films.addVideoGalleryData(this.formFilm);
  }

  removeVideoGalleryData(index) {
    if (confirm(`Si è sicuri di eliminare questa scheda?`)) {
      const videoGalleryData = this.formFilm.get('videoGalleryData') as UntypedFormArray;
      videoGalleryData.removeAt(index);
    }
  }

}
