import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { tap, map, take } from 'rxjs/operators';

@Injectable()
export class GuardService implements CanActivate {
  constructor(
    private router: Router) {
  }

  /**
   * Questo viene eseguito ad ogni router (tranne la login)
   * controlla se esistono
   * @param next
   * @param state
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const session_id = localStorage.getItem('session_id');
    if (session_id && session_id.length > 0) {
      return true;
    } else {
      localStorage.removeItem('session_id');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
