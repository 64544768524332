import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/providers/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  data;
  loading = true;
  displayedColumns: string[] = [
    'name',
    'email',
    'admin',
    'portal',
    'licenza',
    'scadenza',
    'licenza_id',
    'cinema_id',
    'action_modifica'
  ];

  constructor(
    private users: UsersService,
    private router: Router,
  ) {

  }

  buildData() {
    this.data = [];
    // prendo i dati iniziali
    this.loading = true;
    this.users.get()
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.data = result.data;
        }
        this.loading = false;
      });
  }

  ngOnInit() {
    this.buildData();
  }

  add() {
    this.router.navigate(['/user/new']);
  }

  update(id) {
    this.router.navigate([`/user/${id}`]);
  }
}
