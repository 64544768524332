import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CinemasService {
  private _page = 1;  // pagina iniziale
  private _resourceName = 'cinema'; // nome della risorsa
  private _searchFields = 'title'; // in caso di ricerca, dove deve cercare
  
  constructor(private baseProvider: BaseService, private fb: UntypedFormBuilder) {
    
  }

  getForm(data?): UntypedFormGroup {
    data = data || {
      _id: '',
      id: '',
      title: '',
      indirizzo: '',
      comune: '',
      cap: '',
      provincia: '',
      regione: '',
      sito: '',
      mail: '',
      telefono: '',
      fax: '',
      lat: '',
      lng: '',
      films: [],
      imageGalleryData : [],
      circuito : '',
      associazione : '',
      iosapp : '',
      windowsapp : '',
      android_url : '',
      permalink : '',
      sync : false,
      url_sync : '',
      name_sync: ''
    };

    const form = this.fb.group({
      _id: [data['_id'], Validators.nullValidator],
      id: [data['id'], Validators.nullValidator],
      title: [data['title'], Validators.required],
      indirizzo: [data['indirizzo'], Validators.nullValidator],
      comune: [data['comune'], Validators.nullValidator],
      cap: [data['cap'], Validators.nullValidator],
      provincia: [data['provincia'], Validators.nullValidator],
      regione: [data['regione'], Validators.nullValidator],
      sito: [data['sito'], Validators.nullValidator],
      mail: [data['mail'], Validators.nullValidator],
      telefono: [data['telefono'], Validators.nullValidator],
      fax: [data['fax'], Validators.nullValidator],
      lat: [data['lat'], Validators.nullValidator],
      lng: [data['lng'], Validators.nullValidator],
      films: [data['films'], Validators.nullValidator],
      imageGalleryData: this.fb.array([]),
      circuito :  [data['circuito'], Validators.nullValidator],
      associazione :  [data['associazione'], Validators.nullValidator],
      iosapp :  [data['iosapp'], Validators.nullValidator],
      windowsapp :  [data['windowsapp'], Validators.nullValidator],
      android_url :  [data['android_url'], Validators.nullValidator],
      permalink :  [data['permalink'], Validators.nullValidator],
      sync :  [data['sync'], Validators.nullValidator],
      url_sync :  [data['url_sync'], Validators.nullValidator],
      name_sync :  [data['name_sync'], Validators.nullValidator],
    });

    // inserisco le image gallery data
    const imageGalleryData = form.get('imageGalleryData') as UntypedFormArray;
    if ( data['imageGalleryData'] ){
      data['imageGalleryData'].map((igd) => {
        if (igd.content) {
          imageGalleryData.push(this.fb.group({
            title: igd.title,
            content: igd.content,
            orderitem: igd.orderitem || 0
          }));
        }
      });
    }

    return form;
  }


  addImageGalleryData(form) {
    const imageGalleryData = form.get('imageGalleryData') as UntypedFormArray;
    imageGalleryData.push(this.fb.group({
      title: '',
      content: '',
      orderitem: 0
    }));
  }

    /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(id?: string, search?: string) {
    id = id || null;
    search = search || '';

    if (id === null) {
      // richiedo la lista
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: this._searchFields,
        search: search,
        sort: 'title'
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

  /**
   * Ritorna regione del cienma
   */
  getRegione() {
    return this.baseProvider.utilsExternal(`regione`, null);
  }


  /**
   * Esegue il salvataggio o la modifica di una risorsa
   * @param form - la form
   */
  save(form: UntypedFormGroup) {
    if (form.value['_id'] !== null && form.value['_id'] !== '' && form.value['_id'].length > 0) {
      // sono in modifica
      return this.baseProvider.update(this._resourceName, form.value);
    } else {
      // sono in inserimento
      const d = form.value;
      delete d._id;
      return this.baseProvider.insert(this._resourceName, d);
    }
  }

  /**
   * Esegue l'eliminazione
   * @param id
   */
  delete(id: string) {
    return this.baseProvider.delete(this._resourceName, id);
  }

  /**
   * Setta una nuova pagina
   * @param page
   */
  setPage(page: number) {
    this._page = page;
  }



}
