import { Component, OnInit, Inject } from '@angular/core';
import { SimilarService } from './../../providers/similar.service';
import { Observable, debounceTime, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';

import { Router } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiService } from 'src/app/services/api/api.service';
import { FormControl } from '@angular/forms';
import { FilmsService } from 'src/app/providers/films.service';

export interface DialogFilm {
  film?:any,
  db_film?:any,
  cinema?:any,
  errors?:any
}

let data = [];

@Component({
  selector: 'app-similar',
  templateUrl: './similar.component.html',
  styleUrls: ['./similar.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class SimilarComponent implements OnInit {

  searchText = '';
  displayedColumns: string[] = ['title','API','cinema','stato'];

  totalFilms = 0;
  notFound = 0;
  associated = 0;
  notAssociated = 0;
  lastUpdate = '';
  errorsCounter = 0;
  errorsLog = '';

  currentPage = 1;
  totalPage = 0;
  loading = true;
  expandedElement: any;
  dataSource;
  filmSelected : string = 'Nessuno';
  indexSimilar: number = 0;
  order = 0;

  data: any[] = [];

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  constructor(private films: FilmsService, private similar: SimilarService, public dialog: MatDialog,  private router: Router, public _api$: ApiService) {
  }

  filmControl = new FormControl();
  filteredFilms: Observable<any[]>;

  searchedFilm:any;


  ngOnInit() {
    this.order = 0;
    this.buildData();
    this.similar.get('stats',null, (this.searchText.length > 0) ? this.searchText : null,null)
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.notFound = result.data[0].notFound;
          this.associated = result.data[0].associated;
          this.notAssociated = result.data[0].notAssociated;
          this.lastUpdate = result.data[0].update;
          this.errorsCounter = result.data[0].errors.length;
          this.errorsLog = result.data[0].errors;
        }
      });
    this.fetchFilmsFromDB();
    this.fetchFilmStats();

    this.filmControl.valueChanges
      .pipe(
        debounceTime(300),             // Aggiungi un ritardo per evitare troppe chiamate al database
        distinctUntilChanged(),        // Evita chiamate duplicate se il valore non cambia
        switchMap(value => this.searchFilms(value))
      )
      .subscribe(films => this.filteredFilms = of(films));

    this.filmControl.valueChanges.subscribe(value => {
      console.log('Film selezionato:', value);
      this.searchedFilm = value;
    });
   
  }
  
  fetchFilmsFromDB() {
    this.data = [];
    this.loading = true;
    this.films.get(null, (this.searchText && this.searchText.length > 0) ? this.searchText : null)
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.data = result.data;
          // this.currentPage = +result.currentpage;
          // this.totalPage = +result.totalpages;
          // this.totalFilms = result.countall;
        }
        this.loading = false;
      });
  }

  fetchFilmStats() {
    this.similar.get('stats', null, (this.searchText.length > 0) ? this.searchText : null, null)
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.notFound = result.data[0].notFound;
          this.associated = result.data[0].associated;
          this.notAssociated = result.data[0].notAssociated;
          this.lastUpdate = result.data[0].update;
          this.errorsCounter = result.data[0].errors.length;
          this.errorsLog = result.data[0].errors;
        }
      });
  }

  searchFilms(query: string): Observable<any[]> {
    if (!query) {
      return of([]);
    }

    return new Observable(observer => {
      this.films.get(null, query).subscribe((result) => {
        if (result && result['success'] === true) {
          observer.next(result.data);
          observer.complete();
        } else {
          observer.error(new Error('Failed to fetch films'));
        }
      });
    });
  }

  resetFilmSelected(){
    this.filmSelected = 'Nessuno';
    this.indexSimilar = 0;
  }

  getFilmSelected(row){
    console.log(row)
  }

  goSearch() {
    this.similar.setPage(1);
    this.buildData();
  }

  displayFilmFn(film?: any): string | undefined {
    return film ? film.title : undefined;
  }

  goToPage(page) {
    this.similar.setPage(page);
    (this.order > 0) ? this.getSelectedSimilar(this.order) : this.buildData();
  }
  buildData() {
    // prendo i dati iniziali
    this.order = 0;
    this.loading = true;
    this.similar.get('confidence', null, (this.searchText.length > 0) ? this.searchText : null, 'title').subscribe((result: any) => {
      console.log('result', result)
      if (result && result.success === true) {
        this.totalFilms = result.countall;
        const data = result.data || []; // initialize data with an empty array if result.data is null or undefined
        this.dataSource = new Similars(data);
        this.currentPage = +result.currentpage;
        this.totalPage = +result.totalpages;
      }
      this.loading = false;
    });

  }

  add() {
    this.router.navigate(['/film/new']);
  }

  getSelectedSimilar(order) {
    if (order != this.order) {
      this.similar.setPage(1)
    }
    this.order = order;
    this.loading = true;
    this.similar.get('confidence', null, (order > 0) ? order : null, (order > 0) ? 'order' : 'title')
      .subscribe((result) => {
        if (result && result['success'] === true) {
          const data = result.data; // declare a local variable and assign result.data to it
          (order == 0) ? this.totalFilms = result.countall : this.totalFilms = this.totalFilms;
          this.dataSource = new Similars(data);
          this.currentPage = +result.currentpage;
          this.totalPage = +result.totalpages;
        }
        this.loading = false;
      });
  }


  addFilm(el, i, selectedFilmFromAutocomplete) {
    this.indexSimilar = i;
    this.filmSelected = el.title;
    let dbFilmData;
    let index;

    if (selectedFilmFromAutocomplete) {
      // Se selectedFilmFromAutocomplete è fornito, usa i dati da esso
      dbFilmData = {
        title: selectedFilmFromAutocomplete.title,
        id: selectedFilmFromAutocomplete._id,
        _id: selectedFilmFromAutocomplete._id,
        confidenza: 1,
        percentuale: 100,
        isAssociation: false
      };
      index = -1;
    } else {
      // Altrimenti, usa i dati originali
      dbFilmData = el.similar[i];
      index = i
    }

    const dialogRef = this.dialog.open(DialogFilmDialog, {
      width: '30%',
      data: {
        db_film: dbFilmData,
        film: this.filmSelected,
        id_film: el._id,
        index: index
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this._api$.changeAssociated(result).subscribe(v => {
        this.getSelectedSimilar(this.order);
      })
    });
  }



  showErrors(){
    const dialogRef = this.dialog.open(DialogFilmDialog, {
      width: '60%',
      data: {
        errors: this.errorsLog
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  showInfo(el){
    const dialogRef = this.dialog.open(DialogFilmDialog, {
      width: '60%',
      data: {
        cinema: el
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}

export class Similars extends DataSource<any> {
  constructor(private data: any[]) {
    super();
  }
  connect(): Observable<any[]> {
    const rows = [];
    this.data.forEach(element => rows.push(element, { detailRow: true, element }));
    return of(rows);
  }

  disconnect() { }
}

@Component({
  selector: 'app-dialog-film-dialog',
  templateUrl: './dialog-film-dialog.html',
  styleUrls: ['./similar.component.scss'],
})

export class DialogFilmDialog {

  constructor(public dialogRef: MatDialogRef<DialogFilmDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogFilm) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close({id_film:this.data['id_film'], id_ass_film : this.data.db_film['id'], index: this.data['index']});
  }
}


