import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, concat, filter, map, merge, Observable, startWith, switchMap, switchMapTo, tap, throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public http: HttpClient,
    public router: Router
  ) { }

  /**
   * Elabora il body della risorsa
   * ritorna un json, in caso di errore
   * ritorna un JSON vuoto
   * @param res - La risorsa da elaborare
   */
  private extractData(res) {
    return res || {};
  }

  import(): Observable<any> {
    return new Observable(observer => {
      const eventSource = new EventSource(`${environment.url}/import?token=${localStorage.session_id}`);

      eventSource.addEventListener('chunk', (event) => {

        observer.next(event.data);
      });

      eventSource.addEventListener('close', (event) => {

        eventSource.close();
        observer.complete();
      });

      eventSource.addEventListener('error', (event: any) => {

        eventSource.close();
        observer.error(event.data);
      });

      return () => {
        eventSource.close();

      };
    });
  }


 
  sync(): Observable<any> {
    return new Observable(observer => {
      const eventSource = new EventSource(`${environment.url}/sync?token=${localStorage.session_id}`);

      eventSource.addEventListener('chunk', (event) => {
        
        observer.next(event.data);
      });

      eventSource.addEventListener('close', (event) => {
       
        eventSource.close();
        observer.complete();
      });

      eventSource.addEventListener('error', (event: any) => {
        
        eventSource.close();
        observer.error(event.data);
      });

      return () => {
        eventSource.close();
       
      };
    });
  }



  /**
   * Esegue l'autenticazione
   * @param email - L'indirizzo email
   * @param password - La password
   */
  public login(email, password): Observable<any> {
    var data = {email:'',password:''};
    data.email = email;
    data.password = password;
    console.log(data)
    const headers = new HttpHeaders({'Content-type': 'application/json; charset=utf-8'});
    return this.http.post(environment.url + `/login`, data, { headers: headers})
  }

 /**
   * Esegue il cambio di film confidence
   * @param data - Id film da ccambiare l'associazione e id film che diventa l'associato primario
   */
  public changeAssociated(data): Observable<any> {
    const resource = '/addAssociation';
    var params = '';
    Object.keys(data).map((film) => { params += '/'+data[film] });
    const headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded','Accept': 'application/json','Authorization': `Bearer ${localStorage.getItem('session_id')}`});
    headers.set('Content-type', 'application/x-www-form-urlencoded');
    const url = environment.url + `${resource}${params}`;
    return this.http.post(url, {}, {headers:headers});
  }


  /**
   * Esegue una chiamata GET al webserver
   * @param resource il nome della risorsa
   * @param params eventuali dati in get
   * @param baseUrl se non definita chiama la classica /portal/... in caso posso personalizzare la path
   */
  public get(resource, params?, baseUrl?): Observable<any> {
    baseUrl = baseUrl || environment.url;
      // parametri
    const body = new URLSearchParams();
    params = params || [];
    Object.keys(params).map((ok) => { body.set(ok, params[ok]); });

    // creo l'intestazione
    const headers = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded','Accept': 'application/json','Authorization': `Bearer ${localStorage.getItem('session_id')}`});
    headers.set('Content-type', 'application/x-www-form-urlencoded');


    const url = baseUrl + `${resource}${(body.toString().length > 0) ? '?' + body.toString() : ''}`;
    return this.http.get(url, { headers: headers});
  }

  /**
   * Esegue una chiamata POST
   * @param resource la risorsa
   * @param data i dati da salvare
   * @param params eventuali parametri in GET
   */
  public post(resource, data?, params?): Observable<any> {
    return new Observable(observer => {
      // parametri
      const body = new URLSearchParams();
      params = params || [];
      Object.keys(params).map((ok) => { body.set(ok, params[ok]); });

      // creo l'intestazione
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${localStorage.session_id}`);
      const options = { headers: headers };

      console.log(options)

      const url = environment.url + `${resource}${(body.toString().length > 0) ? '?' + body.toString() : ''}`;
      this.http.post(url, JSON.stringify(data), options)
        .subscribe(result => {
          result = this.extractData(result);
          // controllo il risultato
          // se è falso controllo se questo fallimento c'entra con la sessione
          if (result && result['success'] === false && result['tologin'] && result['tologin'] === true) {
            this.router.navigate(['/login']);
          }
          observer.next(result);
          observer.complete();
        });
    });
  }

  /**
   * Eseguo la delete di una risorsa
   * @param resource
   */
  public delete(resource): Observable<any> {
    return new Observable(observer => {
      // creo l'intestazione
      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${localStorage.session_id}`);
      const options = { headers: headers };

      const url = environment.url + `${resource}`;
      this.http.delete(url, options)
        .subscribe(result => {
          result = this.extractData(result);
          // controllo il risultato
          // se è falso controllo se questo fallimento c'entra con la sessione
          if (result && result['success'] === false && result['tologin'] && result['tologin'] === true) {
            this.router.navigate(['/login']);
          }
          observer.next(result);
          observer.complete();
        });
    });
  }

  private readFile(file): Observable<any> {
    return new Observable(observer => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const bstr: string = e.target.result;
          observer.next(bstr);
          observer.complete();
        } catch (e) {
          observer.error();
          observer.complete();
        }
      };
      reader.readAsBinaryString(file);
    });
  }

  public uploadImage(type, file): Observable<string> {
    return new Observable((observer) => {
      const formdata: FormData = new FormData();
      formdata.append('file', file);

      const url = environment.images + `/upload/image/type/${type}`;
      this.http.post(url, formdata)
        .subscribe(result => {
          result = this.extractData(result);
          // controllo il risultato
          // se è falso controllo se questo fallimento c'entra con la sessione
          if (result && result['success'] === false && result['tologin'] && result['tologin'] === true) {
            this.router.navigate(['/login']);
          }

          if (result && result['success'] === false) {
            observer.next(null);
            observer.complete();
          }

          observer.next(result['filename']);
          observer.complete();
        }, () => {
          observer.next(null);
          observer.complete();
        });
    });


  }
}
