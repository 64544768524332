import { BaseService } from './_base.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilmsNaService {

  constructor(private baseProvider: BaseService) { }

  /**
   * Ritorna regione del cienma
   */
  getFilmNotAssociated() {
    return this.baseProvider.utilsExternal(`not_ass_film`, null);
  }

  getDBFilms(){
    return this.baseProvider.utilsExternal(`db_film`, null);
  }
}
