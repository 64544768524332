import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LicensesService } from 'src/app/providers/licenses.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  outputLines: string[] = [];

  constructor(public api$: ApiService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onImportClick() {
    this.outputLines = [];
    this.api$.import().subscribe(
      (chunk) => {
        console.log(chunk);
        this.outputLines.push(chunk);
      },
      (error) => {
        console.error('Error:', error);
      },
      () => {
        console.log('Sync completed');
      }
    );
  }

  onSyncClick() {
    this.outputLines = [];
    this.api$.sync().subscribe(
      (chunk) => {
        console.log(chunk);
        this.outputLines.push(chunk);
      },
      (error) => {
        console.error('Error:', error);
      },
      () => {
        console.log('Sync completed');
      }
    );
  }

}
