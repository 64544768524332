import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FilmsService } from 'src/app/providers/films.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

export interface DialogSimilar {
  screening?: any,
  title?: any,
  api?: any,
  img?:any,
  imageUrl?:any,
  apiTitle?:any,
  prop?:any
}

@Component({
  selector: 'app-films',
  templateUrl: './films.component.html',
  styleUrls: ['./films.component.scss']
})
export class FilmsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  data;
  loading = true;
  displayedColumns: string[] = ['thumbnail_src_name', 'title', 'proiezioni', 'dataUscita','id', 'action_modifica'];
  imageUrl = environment.images;

  // definizione variabili per la gestione della paginazione
  currentPage = 1;
  totalPage = 0;
  totalFilms = 0;

  // definizione gestione ricerca
  searchText = '';

  constructor(
    private films: FilmsService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      console.log('Sorting triggered!');
      this.buildData();
    });
  }

  buildData() {
    this.data = [];
    // prendo i dati iniziali
    this.loading = true;
    let sortOrder = '-dataUscita'
    if (this.sort){
      sortOrder = this.sort.direction === 'asc' ? this.sort.active : '-' + this.sort.active;
    }
    
    this.films.get(null, (this.searchText.length > 0) ? this.searchText : null, sortOrder)
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.data = result.data;
          this.currentPage = +result.currentpage;
          this.totalPage = +result.totalpages;
          this.totalFilms = result.countall;
        }
        this.loading = false;
      });
  }

  getScreenings(api, tit, screen,img,apiTitle,prop) {
    const dialogRef = this.dialog.open(DialogSimilarDialog, {
      height:'95%',
      data: {
        screening: screen,
        title: tit,
        api: api,
        apiTitle: apiTitle,
        img: img,
        prop:prop
        //imageUrl : this.imageUrl
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
    this.buildData();
  }

  add() {
    this.router.navigate(['/film/new']);
  }

  update(id) {
    this.router.navigate([`/film/${id}`]);
  }

  // gestisce la paginazione
  goToPage(page) {
    this.films.setPage(page);
    this.buildData();
  }

  // gestisce le ricerca
  goSearch() {
    this.films.setPage(1);
    this.buildData();
  }

}

@Component({
  selector: 'app-dialog-similar-dialog',
  templateUrl: './dialog-similar-dialog.html',
  styleUrls: ['./films.component.scss']
})

export class DialogSimilarDialog {

  constructor(public dialogRef: MatDialogRef<DialogSimilarDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogSimilar) { }

  flattenedScreenings = [];


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(){
    this.data.screening.forEach(screening => {
      screening.proiezioni.forEach(proiezione => {
        console.log(screening)
        this.flattenedScreenings.push({
          cinemaDBname: screening.cinemaDBname,
          cinemaDBid: screening.cinemaDBid,
          Properties: screening.Properties,
          data_proiezione: proiezione.data_proiezione,
          ora_proiezione: proiezione.ora_proiezione,
          Is3D: proiezione.Is3D
        });
      });
    });
  }
}

