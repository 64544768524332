import { Injectable } from '@angular/core';
import { BaseService } from './_base.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FilmsService {
  private _page = 1;  // pagina iniziale
  private _resourceName = 'films'; // nome della risorsa
  private _searchFields = 'title,titoloOriginale,id'; // in caso di ricerca, dove deve cercare

  constructor(
    private baseProvider: BaseService,
    private fb: UntypedFormBuilder
  ) {
  }

  getForm(data?): FormGroup {
    // Assegna valori di default se `data` è null o undefined
    const defaultValue = {
      _id: '',
      id: '',
      thumbnail_src: '',
      title: '',
      tramaCorta: '',
      tramaLunga: '',
      tramaweb: '',
      durataOre: '0', // assegna '0' come default se vuoi che sia una stringa
      durataMinuti: '0', // assegna '0' come default se vuoi che sia una stringa
      dataUscita: '',
      nazionalita: '',
      formato: '',
      titoloOriginale: '',
      annoDiProduzione: '',
      produzione: '',
      videoGalleryData: [],
      genereFilmData: [],
      regiaData: [],
      regiaBio: '',
      attoriData: [],
      musicheData: [],
      produzioneData: [],
      fotografiaData: [],
      sceneggiaturaData: [],
      montaggioData: [],
      distribuzioneData: [],
      thumbnail_src_name: '',
      thumbnail_src_name_copertina: '',
      articoli: [],
      videorecensioni: [],
      cinema: [],
      code: ''
    };

    // Combina gli oggetti `data` e `defaultValue`, sovrascrivendo i valori di default con quelli in `data`
    data = { ...defaultValue, ...data };

    // Lista dei campi che devono essere sempre array
    const arrayFields = ['genereFilmData', 'regiaData', 'attoriData',
      'musicheData', 'produzioneData', 'fotografiaData',
      'sceneggiaturaData', 'montaggioData', 'distribuzioneData', 'articoli', 'videorecensioni', 'cinema'];

    arrayFields.forEach(field => {
      if (!Array.isArray(data[field])) {
        data[field] = data[field] ? [data[field]] : [];
      }
    });

    // Resto del codice...
    const form = this.fb.group({
      _id: [data._id],
      id: [data.id],
      thumbnail_src: [data.thumbnail_src],
      title: [data.title, Validators.required],
      tramaCorta: [data.tramaCorta],
      tramaLunga: [data.tramaLunga],
      tramaweb: [data.tramaweb],
      durataOre: [data.durataOre],
      durataMinuti: [data.durataMinuti],
      dataUscita: [data.dataUscita],
      nazionalita: [data.nazionalita],
      formato: [data.formato],
      titoloOriginale: [data.titoloOriginale],
      annoDiProduzione: [data.annoDiProduzione],
      produzione: [data.produzione],
      videoGalleryData: this.fb.array(data.videoGalleryData.map(vgd => this.fb.group({
        title: [vgd.title || ''], // Default value for title
        content: [vgd.content || ''], // Default value for content
        orderitem: [vgd.orderitem || 0] // Default value for orderitem
      }))),
      genereFilmData: [data.genereFilmData],
      regiaData: [data.regiaData],
      regiaBio: [data.regiaBio || ''],
      attoriData: [data.attoriData],
      musicheData: [data.musicheData],
      produzioneData: [data.produzioneData],
      fotografiaData: [data.fotografiaData],
      sceneggiaturaData: [data.sceneggiaturaData],
      montaggioData: [data.montaggioData],
      distribuzioneData: [data.distribuzioneData],
      thumbnail_src_name: [data.thumbnail_src_name],
      thumbnail_src_name_copertina: [data.thumbnail_src_name_copertina],
      articoli: [data.articoli],
      videorecensioni: [data.videorecensioni],
      cinema: [data.cinema],
      code: [data.code],
    });

    // inserisco le viode gallery data
    const videoGalleryData = form.get('videoGalleryData') as UntypedFormArray;
    data['videoGalleryData'].map((vgd) => {
      if (vgd.content) {
        videoGalleryData.push(this.fb.group({
          title: vgd.title,
          content: vgd.content,
          orderitem: vgd.orderitem || 0
        }));
      }
    });


    return form;
  }

  addVideoGalleryData(form) {
    const videoGalleryData = form.get('videoGalleryData') as UntypedFormArray;
    videoGalleryData.push(this.fb.group({
      title: '',
      content: '',
      orderitem: 0
    }));
  }

  /**
   * Ritorna la lista o un singolo elemento
   * @param id - identifica se voglio che mi ritorni un determinato elemento
   * @param search - eventualmente una riceca
   */
  get(id?: string, search?: string, sort?: string) {

    id = id || null;
    search = search || '';

    if (id === null) {
      // richiedo la lista
      return this.baseProvider.list(this._resourceName, {
        page: this._page,
        searchFields: this._searchFields,
        search: search,
        sort: sort || '-dataUscita'
      });
    } else {
      // richiedo un singolo oggetto
      return this.baseProvider.single(this._resourceName, id);
    }
  }

  /**
   * Ritorna tutti i generi dei film
   */
  getGeneri() {
    return this.baseProvider.utilsExternal('generi', null);
  }

  /**
   * Ritorna tutti gli attori
   */
  getAttori() {
    return this.baseProvider.utilsExternal(`attori`, null);
  }

  /**
   * Ritorna tutti gli regia
   */
  getRegia() {
    return this.baseProvider.utilsExternal(`regia`, null);
  }

  /**
   * Ritorna tutti gli musica
   */
  getMusiche() {
    return this.baseProvider.utilsExternal(`musica`, null);
  }

  /**
   * Ritorna tutti gli produzione
   */
  getProduzione() {
    return this.baseProvider.utilsExternal(`produzione`, null);
  }

  /**
   * Ritorna tutti gli fotografia
   */
  getFotografia() {
    return this.baseProvider.utilsExternal(`fotografia`, null);
  }

  /**
   * Ritorna tutti gli sceneggiatura
   */
  getSceneggiatura() {
    return this.baseProvider.utilsExternal(`sceneggiatura`, null);
  }

  /**
   * Ritorna tutti gli montaggio
   */
  getMontaggio() {
    return this.baseProvider.utilsExternal(`montaggio`, null);
  }

  /**
   * Ritorna tutti gli distribuzione
   */
  getDistribuzione() {
    return this.baseProvider.utilsExternal(`distribuzione`, null);
  }

  /**
   * Esegue il salvataggio o la modifica di una risorsa
   * @param form - la form
   */
  save(form: FormGroup) {
    if (form.get('_id').value !== null && form.get('_id').value !== '') {
      // sono in modifica
      return this.baseProvider.update(this._resourceName, form.value);
    } else {
      // sono in inserimento
      const d = form.value;
      delete d._id;
      return this.baseProvider.insert(this._resourceName, d);
    }
  }

  /**
   * Esegue l'eliminazione
   * @param id
   */
  delete(id: string) {
    return this.baseProvider.delete(this._resourceName, id);
  }

  /**
   * Setta una nuova pagina
   * @param page
   */
  setPage(page: number) {
    this._page = page;
  }


}
