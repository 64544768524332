import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api/api.service';
import { environment } from 'src/environments/environment';

export interface BaseServiceParams {
  page: number;
  searchFields: string;
  search?: string;
  sort?: string;
  filter?:string;
}

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private api: ApiService
  ) { }

  /**
   * Ritorna una lista di oggetti di una risorsa
   * @param resource il nome della risorsa
   * @param params i parametri
   *    - page: la pagina da visualizzare
   *    - searchFields: una stringa separata da virgola con le colonne per la ricerca
   *    - search: <opzionale> la stringa di ricerca
   *    - sort: <opzionale> indica il campo da ordinare
   */
  public list(resource: string, params: BaseServiceParams) {
    return this.api.get(`/resources/${resource}`, params);
  }

  /**
   * Esegue una risorsa esterna
   * @param resource
   * @param params
   */
  public utilsExternal(resource: string, params: BaseServiceParams) {
    return this.api.get(`${resource}`, params, `${environment.utils}/`);
  }

  /**
   * Ritorna un singolo elemento
   * @param resource il nome della risorsa
   * @param id il parametro
   */
  public single(resource: string, id: string) {
    return this.api.get(`/resources/${resource}/${id}`);
  }

  /**
   * Inserisce un elemento in una risora
   * @param resource il nome della risorsa
   * @param data i dati da inserire
   */
  public insert(resource: string, data: any) {
    return this.api.post(`/resources/${resource}`, data);
  }

  /**
   * Esegue l'update di una risora
   * @param resource il nome della risorsa
   * @param data i dati da modificare
   */
  public update(resource: string, data: any) {
    const id = data['_id'];
    delete data._id;
   
    return this.api.post(`/resources/${resource}/${id}`, data);
  }

  public delete(resource: string, id: string) {
    return this.api.delete(`/resources/${resource}/${id}`);
  }

}
