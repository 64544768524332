import { FilmsService } from './../../providers/films.service';
import { Component, OnInit} from '@angular/core';
import { FilmsNaService } from 'src/app/providers/films-na.service';
import { Observable } from 'rxjs';
import { UntypedFormGroup, FormControl,UntypedFormBuilder, Validators, FormArray} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
  selector: 'app-add-similar',
  templateUrl: './add-similar.component.html',
  styleUrls: ['./add-similar.component.scss']
})
export class AddSimilarComponent implements OnInit {

  notAssFilms: any;
  filteredFilmsNA: Observable<string[]>;

  films: any;
  filteredFilms: Observable<string[]>;

  full_na_films;
  full_films;
  na_films_count = 0;
  films_count = 0;
  fg: UntypedFormGroup;
  loading = true;

  constructor(private _naFilm: FilmsNaService, private router: Router, private fb: UntypedFormBuilder) {
    this.fg = this.fb.group({
      na_film: ['', [Validators.required]],
      film: ['', [Validators.required]]
    });
  }

  ngOnInit() {

    this._naFilm.getFilmNotAssociated().subscribe((notAssDataFilms) => {
      this.loading = false;
      this.full_na_films = notAssDataFilms.data;
      this.notAssFilms = notAssDataFilms.data.map(v => v.title);
      this.na_films_count = notAssDataFilms.count;
      this.filteredFilmsNA = this.fg.get('na_film').valueChanges.pipe(
        startWith(''),
        map((val:any) =>
          this.filterNA(val)
        )
      );
    });

    this._naFilm.getDBFilms().subscribe((dataFilms) => {
      this.loading = false;
      this.full_films = dataFilms.data;
      this.films = dataFilms.data.map(v => v.title);
      this.films_count = dataFilms.count;
      if ( !this.fg.get('na_film').value) {
        this.fg.get('film').disable();
      } else {
        this.fg.get('film').enable();
      }
      this.fg.get('na_film').valueChanges.subscribe(na_film_selected => {
        if (!na_film_selected) {
          this.fg.get('film').disable();
        } else {
          this.fg.get('film').enable();
        }
      });
      this.filteredFilms = this.fg.get('film').valueChanges.pipe(
        startWith(''),
        map((val:any) =>
          this.filter(val)
        )
      );
    });
  }

  filterNA(val: string): string[] {
    return this.notAssFilms.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  filter(val: string): string[] {
    return this.films.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  addFilm(){
    let na_film_target = this.fg.get('na_film').value;
    let film_target = this.fg.get('film').value;
    let na_film_data = this.full_na_films[this.full_na_films.findIndex(val => val.title === na_film_target)];
    let film_id = this.full_films[this.full_films.findIndex(val => val.title === film_target)]._id;
    console.log(na_film_data)
  }

}
