import { Component, OnInit } from '@angular/core';
import { LicensesService } from 'src/app/providers/licenses.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {
  data;
  loading = true;
  displayedColumns: string[] = ['code', 'name', 'action_modifica'];

  constructor(
    private license: LicensesService,
    private router: Router,
  ) {

  }

  buildData() {
    this.data = [];
    // prendo i dati iniziali
    this.loading = true;
    this.license.get()
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.data = result.data;
          console.log(this.data);
        }
        this.loading = false;
      });
  }

  ngOnInit() {
    this.buildData();
  }

  add() {
    this.router.navigate(['/license/new']);
  }

  update(id) {
    this.router.navigate([`/license/${id}`]);
  }

}
