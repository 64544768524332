import { CinemasService } from './../../providers/cinemas.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cinemas',
  templateUrl: './cinemas.component.html',
  styleUrls: ['./cinemas.component.scss']
})
export class CinemasComponent implements OnInit {

  data;
  loading = true;
  displayedColumns: string[] = ['title', 'indirizzo', 'provincia' ,'regione', 'contatto' ,'sito','proiezioni','action_modifica'];


  // definizione variabili per la gestione della paginazione
  currentPage = 1;
  totalPage = 0;
  totalCinemas = 0;

  // definizione gestione ricerca
  searchText = '';

  constructor(
    private cinemas: CinemasService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  buildData() {
    this.data = [];
    // prendo i dati iniziali
    this.loading = true;
    this.cinemas.get(null, (this.searchText.length > 0) ? this.searchText : null)
      .subscribe((result) => {
        if (result && result['success'] === true) {
          this.data = result.data;
          this.currentPage = +result.currentpage;
          this.totalPage = +result.totalpages;
          this.totalCinemas = result.countall;
        }
        this.loading = false;
      });
  }

  ngOnInit() {
    this.buildData();
  }

  add() {
    this.router.navigate(['/cinema/new']);
  }

  update(id) {
    this.router.navigate([`/cinema/${id}`]);
  }

  // gestisce la paginazione
  goToPage(page) {
    this.cinemas.setPage(page);
    this.buildData();
  }

  // gestisce le ricerca
  goSearch() {
    this.cinemas.setPage(1);
    this.buildData();
  }

  openProiezioni(films) {
    const dialogRef = this.dialog.open(DialogCinemas, {
      width: '99vw',
      height: '99vh',
      data: {
        films: films
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}

@Component({
  selector: 'app-dialog-cinemas',
  templateUrl: './dialog-proiezioni.html',
  styleUrls: ['./cinemas.component.scss']
})

export class DialogCinemas {

  constructor(public dialogRef: MatDialogRef<DialogCinemas>, @Inject(MAT_DIALOG_DATA) public data) { }

  films_ = [];

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.films_ = this.data.films;
  }
}